import * as React from "react";
import axios from "requests/axios-base";
import Preloader from "Components/Preloader/preloader";
import Toast from "Components/Toast/toast";
import VerifyUI from "Components/VerifyEmail";

const Verify = (props: any) => {
  const [user, setUser] = React.useState("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState({
    show: false,
    heading: "",
    text: "",
    type: true,
  });
  React.useEffect(() => {
    setUser(props?.location?.state?.user);
    // console.log(props?.location?.state?.user);
  }, [props.location.state.user]);

  const resendVerification = () => {
    setLoading(true);
    const data = {
      email: user,
    };
    axios
      .post(`/affiliate/resend-mail`, data)
      .then((response) => {
        console.log(response);
        openToast(false, `Resent verification mail to ${user}`, "");
      })
      .catch((error) => {
        openToast(
          false,
          error.response.data.message ?? "Failed to resend",
          "Sorry"
        );
        console.log(error.response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const openToast = (type: boolean, text: string, heading: string) => {
    setToast({ show: true, type: type, text: text, heading: heading });
    return setTimeout(() => {
      setToast({ ...toast, show: false });
    }, 1500);
  };
  return (
    <>
      <Toast
        closeModal={() => setToast({ ...toast, show: false })}
        show={toast.show}
        heading={toast.heading}
        text={toast.text}
        type={toast.type}
      />
      {loading && <Preloader />}
      <VerifyUI email={user} resendVerification={resendVerification} />
    </>
  );
};

export default Verify;
