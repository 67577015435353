import * as React from "react";
import { useHistory, useLocation } from "react-router";
import { Routes } from "Utils/routes";
import Preloader from "Components/Preloader/preloader";
import queryString from "query-string";
import axios from "requests/axios-base";
import Toast from "Components/Toast/toast";
import SuccessUI from "Components/VerificationSuccessful";

const Success = () => {
  const location = useLocation();
  const { push } = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState(false);
  const [toast, setToast] = React.useState({
    show: false,
    heading: "",
    text: "",
    type: false,
  });

  async function verifyToken() {
    setLoading(true);
    const values = queryString.parse(location.search);
    try {
      const response = await axios.post(
        `/affiliate/verify-account?token=${values.token}`,
        {
          params: { token: values.token },
        }
      );
      if (response.status === 200) {
        setStatus(true);
      }
    } catch (error: any) {
      // console.log(error.response);
      openToast(false, error.response.data.message, "Sorry");
      setStatus(false);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    verifyToken();
  }, []);

  const openToast = (type: boolean, text: string, heading: string) => {
    setToast({ show: true, type: type, text: text, heading: heading });
    return setTimeout(() => {
      setToast({ ...toast, show: false });
    }, 1500);
  };

  const reverify = (email) => {
    push({
      pathname: Routes.verify,
      state: {
        user: email,
      },
    });
  };
  return (
    <>
      <Toast
        closeModal={() => setToast({ ...toast, show: false })}
        show={toast.show}
        text={toast.text}
        heading={toast.heading}
        type={toast.type}
      />
      {loading ? (
        <Preloader />
      ) : (
        <SuccessUI status={status} verify={(x) => reverify(x)} />
      )}
    </>
  );
};

export default Success;
