import * as React from "react";
import styles from "./styles.module.css";
import LeftTab from "Components/LeftTab";
import { Link } from "react-router-dom";
import leftarr from "assets/vectors/arrowLeft.svg";
import forgot from "assets/vectors/forgot.svg";
import lock from "assets/vectors/lock.svg";
import logo from "assets/vectors/vobbSymbol.svg";
import { Routes } from "Utils/routes";
import show from "assets/vectors/eyeOpen.svg";
import hide from "assets/vectors/eyeClose.svg";

interface ResetProps {
  onPasswordChange: (password: string) => void;
  onConfirmPasswordChange: (password: string) => void;
  reset: () => void;
  token: string;
}

const ResetUI: React.FC<ResetProps> = ({
  onConfirmPasswordChange,
  onPasswordChange,
  reset,
}) => {
  const [state, setState] = React.useState({
    password1: "",
    password2: "",
    empty: false,
  });
  const { password1, password2, empty } = state;
  const [togglePassword, setTogglePassword] = React.useState(false);
  const [togglePassword2, setTogglePassword2] = React.useState(false);

  const onChange = (e: any) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });

    if (name === "password1") {
      onPasswordChange(value);
    } else if (name === "password2") {
      onConfirmPasswordChange(value);
    }
  };

  const verify = (e) => {
    e.preventDefault();
    if (password1 !== password2 && password1 !== "") {
      setState({
        ...state,
        empty: password1 !== password2 && password1 !== "" ? true : false,
      });
    } else return reset();
  };

  return (
    <>
      <div className={styles["container"]}>
        <LeftTab
          text="Discover and interact with credible international study abroad agents for free!"
          img={forgot}
          page="forgot"
          margin="small"
        />
        <div className={styles["info"]}>
          <div className={styles["mobile-hd"]}>
            <Link to={Routes.register}>
              <img src={logo} alt="vobb logo" />
            </Link>
          </div>
          <div className={"info-content"}>
            <h1 className={styles["info-ttl"]}>Reset password</h1>
            <form className={styles["form"]}>
              <div className={styles["form-wrapinput"]}>
                <img src={lock} alt="lock" />
                <input
                  className={
                    empty
                      ? `${styles["form-input"]} ${styles["v-pwd-padd"]} ${styles["empty"]}`
                      : `${styles["form-input"]} ${styles["v-pwd-padd"]}`
                  }
                  type={!togglePassword ? "password" : "text"}
                  placeholder="Enter new password"
                  value={password1}
                  name="password1"
                  onChange={onChange}
                />
                <img
                  className={styles["v-show-pwd"]}
                  onClick={() => setTogglePassword(!togglePassword)}
                  src={!togglePassword ? show : hide}
                  alt="show/hide"
                />
              </div>
              <div className={styles["form-wrapinput"]}>
                <img src={lock} alt="lock" />
                <input
                  className={
                    empty
                      ? `${styles["form-input"]} ${styles["v-pwd-padd"]} ${styles["empty"]}`
                      : `${styles["form-input"]} ${styles["v-pwd-padd"]}`
                  }
                  type={!togglePassword2 ? "password" : "text"}
                  placeholder="Re - enter password"
                  value={password2}
                  name="password2"
                  onChange={onChange}
                />
                <img
                  className={styles["v-show-pwd"]}
                  onClick={() => setTogglePassword2(!togglePassword2)}
                  src={!togglePassword2 ? show : hide}
                  alt="show/hide"
                />
              </div>
              <button
                disabled={password1 !== password2 || !password2 || !password1}
                onClick={verify}
                className={`${styles["form-btn"]} ${styles["form-btn-active"]}`}
              >
                Reset my password
              </button>
            </form>
            <p className={styles["form-txt"]}>
              <Link
                className={`${styles["v-forgot-link"]} ${styles["link"]}`}
                to={Routes.forgotpassword}
              >
                <img src={leftarr} />
                Back to forgot password
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetUI;
