import styles from "./styles.module.css";
import logo from "assets/vectors/vobbSymbol.svg";
import { Link } from "react-router-dom";
// import { Routes } from "../../utils/routes";

const LeftTab = (props: any) => {
  return (
    <>
      <div className={styles["v-signup-imgsec"]}>
        <Link to="">
          <img className={styles["v-logo"]} src={logo} alt="vobb logo" />
        </Link>
        <img
          className={`${styles["v-signup-img"]} ${
            props.page === "forgot" && styles["v-signup-img v-forgot-width"]
          }`}
          src={props.img}
          alt="forgot"
        />
        <p className={`${styles["v-signup-imgsec-txt"]} ${styles[`txtMarg${props.margin}`]}`}>{props.text}</p>
      </div>
    </>
  );
};

export default LeftTab;
