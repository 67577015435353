import * as React from "react";
import Toast from "Components/Toast/toast";
import axios from "requests/axios-base";
import SignupUI from "Components/Signup";
import Preloader from "Components/Preloader/preloader";
import { useHistory } from "react-router-dom";
import { Routes } from "Utils/routes";

const Signup = () => {
  const { push } = useHistory();
  const [email, setEmail] = React.useState<string>("");
  const [name, setName] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [toast, setToast] = React.useState({
    show: false,
    heading: "",
    text: "",
    type: false,
  });
  const [loading, setLoading] = React.useState(false);

  const signup = () => {
    setLoading(true);
    const data = {
      email,
      password,
      name,
    };
    console.log(data);
    axios
      .post("/affiliate/new", data)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          openToast(true, response.data.message, "Great!");
          // Email verification
          setTimeout(() => {
            push({
              pathname: Routes.verify,
              state: {
                user: email,
              },
            });
          }, 2000);
        } else {
          openToast(false, response.data.message, "Sorry");
        }
      })
      .catch((error) => {
        console.error(error);
        console.error(error.response);
        openToast(false, error.response.data.message, "Sorry");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const openToast = (type: boolean, text: string, heading: string) => {
    setToast({ show: true, type: type, text: text, heading: heading });
    return setTimeout(() => {
      setToast({ ...toast, show: false });
    }, 1500);
  };

  return (
    <>
      {loading && <Preloader />}
      <Toast
        closeModal={() => setToast({ ...toast, show: false })}
        show={toast.show}
        text={toast.text}
        heading={toast.heading}
        type={toast.type}
      />
      <SignupUI
        signup={signup}
        handleChangeEmail={(email) => setEmail(email)}
        handleChangeName={(name) => setName(name)}
        handleChangePassword={(password) => setPassword(password)}
      />
    </>
  );
};

export default Signup;
