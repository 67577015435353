import * as React from "react";
import LeftTab from "Components/LeftTab";
import { Link } from "react-router-dom";
import messageIcon from "assets/vectors/message.svg";
import leftarr from "assets/vectors/arrowLeft.svg";
import forgot from "assets/vectors/forgot.svg";
import logo from "assets/vectors/vobbSymbol.svg";
import { Routes } from "Utils/routes";
import styles from "./styles.module.css";

interface ForgotProps {
  onEmailChange: (email: string) => void;
  reset: () => void;
}

const ForgotUI:React.FC<ForgotProps> = ({onEmailChange, reset}) => {
  const [state, setState] = React.useState({
    email: "",
    empty1: false,
  });
  const { email, empty1 } = state;

  const onChange = (e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
    onEmailChange(e.target.value)
  };
  const verify = (e) => {
    e.preventDefault();
    setState({
      ...state,
      empty1: email === "" ? true : false,
    });
    return reset();
  };

  return (
    <>
      <div className={styles["container"]}>
        <LeftTab
          text="Discover and interact with credible international study abroad agents for free!"
          img={forgot}
          page="forgot"
          margin="small"
        />
        <div className={styles["info"]}>
          <div className={styles["mobile-hd"]}>
            <Link to={Routes.register}>
              <img src={logo} alt="vobb logo" />
            </Link>
          </div>
          <div className={"info-content"}>
            <h1 className={styles["info-ttl"]}>Forgot password</h1>
            <p className={styles["info-txt"]}>
              Enter the email you used to register, we will send you
              instructions on how to reset your password
            </p>
            <form>
              <div className={`${styles["form-wrapinput"]} ${styles["wrap"]}`}>
                <img src={messageIcon} alt="message" />
                <input
                  className={
                    empty1 ? styles["form-input empty"] : styles["form-input"]
                  }
                  type="email"
                  placeholder="Email"
                  value={email}
                  name="email"
                  onChange={onChange}
                />
                {empty1 && (
                  <p className={styles["no-mail"]}>
                    Please enter a valid Email Address
                  </p>
                )}
              </div>
              <button
                disabled={!email}
                onClick={verify}
                className={`${styles["form-btn"]} ${styles["form-btn-active"]}`}
              >
                Continue
              </button>
            </form>
            <p className={styles["form-txt"]}>
              <Link className={styles["link"]} to={Routes.login}>
                <img src={leftarr} alt="back" />
                Back to Login
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotUI;
