import DashboardUI from "Pages/Dashboard";
import Forgot from "Pages/ForgotPassword";
import Login from "Pages/Login";
import Reset from "Pages/ResetPassword";
import Success from "Pages/VerificationSuccessful";
import Verify from "Pages/VerifyEmail";
import Signup from "Pages/Signup";
import { useEffect, useState } from "react";
import {
  Switch,
  Route,
  BrowserRouter,
  useLocation,
  withRouter,
  Redirect,
  useHistory,
} from "react-router-dom";
import { Routes } from "Utils/routes";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import axios from "requests/axios-base";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  // const [user, setUser] = useState<boolean>(true);

  // useEffect(() => {
  //   const token = localStorage.getItem("vobbAffiliateToken");

  //   if (!token) {
  //     console.log("no token");
  //     // throw new Error("No token found");
  //     if (window.location.pathname === Routes.overview) {
  //       window.location.assign("/login");
  //     }
  //     return;
  //   }

  //   axios
  //     .post(`affiliate/verify-account?token=${token ?? ""}`)
  //     .then((response) => {
  //       if (response.status !== 200) {
  //         setUser(false);
  //         throw new Error("error checking token");
  //       } else {
  //         setUser(true);
  //       }
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // });

  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <Route exact path="/">
            <Redirect to={Routes.register} />
          </Route>
          <Route exact path={Routes.login} component={Login} />
          <Route exact path={Routes.register} component={Signup} />
          <Route exact path={Routes.verify} component={Verify} />
          <Route exact path={Routes.forgotpassword} component={Forgot} />
          <Route exact path={Routes.resetpassword} component={Reset} />
          <Route
            exact
            path={Routes.verificationSuccessful}
            component={Success}
          />

          {/* {user && ( */}
            <Route exact path={Routes.overview} component={DashboardUI} />
          {/* )} */}
        </Switch>
      </BrowserRouter>
    </div>
  );
};
export default App;
