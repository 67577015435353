import * as React from "react";
import styles from "./login.module.css";
import LeftTab from "Components/LeftTab";
import { Link, Route } from "react-router-dom";
import loginImg from "assets/vectors/login.svg";
import lock from "assets/vectors/lock.svg";
import messageIcon from "assets/vectors/message.svg";
import show from "assets/vectors/eyeOpen.svg";
import hide from "assets/vectors/eyeClose.svg";
import logo from "assets/vectors/vobbSymbol.svg";
import blueTick from "assets/vectors/blueTick.svg";
import { Routes } from "Utils/routes";

interface LoginProps {
  login: () => void;
  handleChangeEmail: (email: string) => void;
  handleChangePassword: (password: string) => void;
  handleChangeIsAgent: (isAgent: boolean) => void;
}

const LoginUI: React.FC<LoginProps> = ({
  login,
  handleChangeEmail,
  handleChangePassword,
  handleChangeIsAgent
}) => {
  // const dispatch = useAppDispatch();
  const [state, setState] = React.useState({
    password: "",
    email: "",
    empty1: false,
    empty2: false,
    message: "",
  });
  const { password, email, empty1, empty2, message } = state;
  const [checked, setChecked] = React.useState(false);
  const [togglePassword, setTogglePassword] = React.useState(false);

  const onChange = (e: any) => {
    const { name, value, checked } = e.target;

    if (name === "email") {
      setState({
        ...state,
        [name]: value,
      });
      handleChangeEmail(value);
    } else if (name === "password") {
      setState({
        ...state,
        [name]: value,
      });
      handleChangePassword(value);
    } else if (name === "agent") {
      setChecked(checked);
      handleChangeIsAgent(checked)
    }
  };
  const verify = (e) => {
    e.preventDefault();
    if (password === "" || email === "") {
      setState({
        ...state,
        empty1: email === "" ? true : false,
        empty2: password === "" ? true : false,
      });
    } else login();
  };

  return (
    <>
      <div className={styles["container"]}>
        <LeftTab
          text="Discover and interact with credible international study abroad agents for free!"
          img={loginImg}
          margin="small"
        />
        <div className={styles["info"]}>
          <div className={styles["mobile-hd"]}>
            <Link to={Routes.register}>
              <img src={logo} alt="vobb logo" />
            </Link>
          </div>
          <div className={styles["info-content"]}>
            <h1 className={styles["info-ttl"]}>Login</h1>
            <p className={styles["info-txt"]}>
              Good to have you back, you have been missed!
            </p>
            <form>
              <div className={styles["form-wrapinput"]}>
                <img src={messageIcon} alt="message" />
                <input
                  className={
                    empty1
                      ? `${styles["form-input"]} ${styles["empty"]}`
                      : styles["form-input"]
                  }
                  type="email"
                  placeholder="Email"
                  value={email}
                  name="email"
                  onChange={onChange}
                />
                {empty1 && (
                  <p className="no-mail">Please enter a valid Email Address</p>
                )}
              </div>
              <div className={styles["form-wrapinput"]}>
                <img src={lock} alt="lock" />
                <input
                  className={
                    empty2
                      ? `${styles["form-input"]} ${styles["v-pwd-padd"]} ${styles["empty"]}`
                      : `${styles["form-input"]} ${styles["v-pwd-padd"]}`
                  }
                  type={!togglePassword ? "password" : "text"}
                  placeholder="Password"
                  value={password}
                  name="password"
                  onChange={onChange}
                />
                <img
                  className={styles["v-show-pwd"]}
                  onClick={() => setTogglePassword(!togglePassword)}
                  src={!togglePassword ? show : hide}
                  alt="show/hide"
                />
              </div>
              <input
                id="register"
                onChange={onChange}
                type="checkbox"
                checked={checked}
                className={styles.input}
                name="agent"
              />
              <label htmlFor="register" className={styles["form-label"]}>
                {checked && (
                  <img
                    className={styles["blueTick"]}
                    src={blueTick}
                    alt="tick"
                  />
                )}
                I'm an agent
              </label>
              <Link className={styles["forgot"]} to={Routes.forgotpassword}>
                Forgot password?
              </Link>
              <button
                onClick={verify}
                disabled={!email || !password}
                className={`${styles["form-btn"]} ${styles["form-btn-active"]}`}
              >
                Login
              </button>
            </form>
            <p className={styles["form-txt"]}>
              Don’t have an Account? <Link to={Routes.register}>Register</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginUI;
