import * as React from "react";
import styles from "./styles.module.css";
import logo from "assets/vectors/vobbSymbol.svg";
import LogoutPrompt from "Components/LogoutPrompt";
import { useHistory } from "react-router-dom";
import { Routes } from "Utils/routes";

const Navbar = () => {
  const {push} = useHistory()
  const [logout, setLogout] = React.useState<boolean>(false);
  const logoutAction = () => {
    setLogout(false);
    localStorage.clear()
    push(Routes.login)
  }
  return (
    <>
      <LogoutPrompt
        show={logout}
        closeModal={() => setLogout(false)}
        logout={logoutAction}
      />
      <nav className={styles.wrap}>
        <img src={logo} alt="Vobb Logo" />
        <button onClick={() => setLogout(true)} className={`appBtnOutline`}>
          Logout
        </button>
      </nav>
    </>
  );
};

export default Navbar;
