import * as React from "react";
import axios from "requests/axios-base";
import Toast from "Components/Toast/toast";
import Preloader from "Components/Preloader/preloader";
import ForgotUI from "Components/ForgotPassword";

const Forgot = (props: any) => {
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [toast, setToast] = React.useState({
    show: false,
    heading: "",
    text: "",
    type: false,
  });

  const reset = () => {
    setLoading(true);
    const data = {
      email: email,
    };
    axios
      .post("/affiliate/forgot_password", data)
      .then((response) => {
        console.log(response);
        openToast(true, response.data.message, "Great!");

        // props.history.push(Routes.resetPassword);
      })
      .catch((error) => {
        console.log(error.response);
        openToast(false, error.response.data.message, "Sorry");
      })
      .finally(() => setLoading(false));
  };
  const openToast = (type: boolean, text: string, heading: string) => {
    setToast({ show: true, type: type, text: text, heading: heading });
    return setTimeout(() => {
      setToast({ ...toast, show: false });
    }, 1500);
  };

  return (
    <>
      {loading && <Preloader />}
      <Toast
        closeModal={() => setToast({ ...toast, show: false })}
        show={toast.show}
        text={toast.text}
        heading={toast.heading}
        type={toast.type}
      />
      <ForgotUI reset={reset} onEmailChange={(email) => setEmail(email)} />
    </>
  );
};

export default Forgot;
