import * as React from "react";
import styles from "./styles.module.css";
import LeftTab from "Components/LeftTab";
import { Link } from "react-router-dom";
import lock from "assets/vectors/lock.svg";
import messageIcon from "assets/vectors/message.svg";
import show from "assets/vectors/eyeOpen.svg";
import hide from "assets/vectors/eyeClose.svg";
import logo from "assets/vectors/vobbSymbol.svg";
import blueTick from "assets/vectors/blueTick.svg";
import signupImg from "assets/vectors/signup.svg";
import { Routes } from "Utils/routes";

interface SignupProps {
  signup: () => void;
  handleChangeName: (name: string) => void;
  handleChangeEmail: (email: string) => void;
  handleChangePassword: (password: string) => void;
}

const SignupUI:React.FC<SignupProps> = ({
  signup,
  handleChangeEmail,
  handleChangeName,
  handleChangePassword,
}) => {
  const [state, setState] = React.useState({
    password: "",
    email: "",
    empty1: false,
    empty2: false,
    empty3: false,
    name: "",
  });
  const { password, email, empty1, empty2, empty3, name } = state;
  const [checked, setChecked] = React.useState(false);
  const [togglePassword, setTogglePassword] = React.useState(false);

  const onChange = (e: any) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });

    if (name === "name") {
      handleChangeName(value);
    } else if (name === "email") {
      handleChangeEmail(value);
    } else if (name === "password") {
      handleChangePassword(value);
    }
  };

  const verify = (e) => {
    e.preventDefault();
    if (!password || !email || !name) {
      setState({
        ...state,
        empty1: name === "" ? true : false,
        empty2: email === "" ? true : false,
        empty3: password === "" ? true : false,
      });
    } else signup();
  };

  return (
    <>
      <div className={styles["container"]}>
        <LeftTab
          text="Discover and interact with credible international study abroad agents for free!"
          img={signupImg}
          margin="small"
        />
        <div className={styles["info"]}>
          <div className={styles["mobile-hd"]}>
            <Link to={Routes.register}>
              <img src={logo} alt="vobb logo" />
            </Link>
          </div>
          <div className={styles["info-content"]}>
            <h1 className={styles["info-ttl"]}>Register</h1>
            <p className={styles["info-txt"]}>
              Signup to become a Vobb Affilliate
            </p>
            <form>
              <div className={styles["form-wrapinput"]}>
                <img src={messageIcon} alt="message" />
                <input
                  className={
                    empty1
                      ? `${styles["form-input"]} ${styles["empty"]}`
                      : styles["form-input"]
                  }
                  type="text"
                  placeholder="Name"
                  value={name}
                  name="name"
                  onChange={onChange}
                />
                {empty1 && <p className="no-mail">Please enter your name</p>}
              </div>
              <div className={styles["form-wrapinput"]}>
                <img src={messageIcon} alt="message" />
                <input
                  className={
                    empty2
                      ? `${styles["form-input"]} ${styles["empty"]}`
                      : styles["form-input"]
                  }
                  type="email"
                  placeholder="Email"
                  value={email}
                  name="email"
                  onChange={onChange}
                />
                {empty2 && (
                  <p className="no-mail">Please enter a valid Email Address</p>
                )}
              </div>
              <div className={styles["form-wrapinput"]}>
                <img src={lock} alt="lock" />
                <input
                  className={
                    empty3
                      ? `${styles["form-input"]} ${styles["v-pwd-padd"]} ${styles["empty"]}`
                      : `${styles["form-input"]} ${styles["v-pwd-padd"]}`
                  }
                  type={!togglePassword ? "password" : "text"}
                  placeholder="Password"
                  value={password}
                  name="password"
                  onChange={onChange}
                />
                <img
                  className={styles["v-show-pwd"]}
                  onClick={() => setTogglePassword(!togglePassword)}
                  src={!togglePassword ? show : hide}
                  alt="show/hide"
                />
              </div>
              <input
                id="register"
                onChange={() => setChecked(checked ? false : true)}
                type="checkbox"
                checked={checked}
                className={styles.input}
              />
              <label htmlFor="register" className={styles["form-label"]}>
                {checked && (
                  <img
                    className={styles["blueTick"]}
                    src={blueTick}
                    alt="tick"
                  />
                )}
                Accept terms and conditions and privacy policy
              </label>
              <button
                onClick={verify}
                disabled={!email || !password || !name}
                className={`${styles["form-btn"]} ${styles["form-btn-active"]}`}
              >
                Register
              </button>
            </form>
            <p className={styles["form-txt"]}>
              Already have an account? Already a Vobb agent?{" "}
              <Link to={Routes.login}>Login</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignupUI;
