import * as React from "react";
import { useHistory } from "react-router-dom";
import axios from "requests/axios-base";
import { Routes } from "Utils/routes";
import LoginUI from "Components/Login/login";
import Preloader from "Components/Preloader/preloader";
import Toast from "Components/Toast/toast";
import { AxiosRequestConfig } from "axios";

const Login = () => {
  const { push } = useHistory();
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [isAgent, setIsAgent] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState({
    show: false,
    heading: "",
    text: "",
    type: false,
  });
  const [loading, setLoading] = React.useState(false);

  const login = () => {
    setLoading(true);
    const data = {
      email,
      password,
    };
    console.log(data, isAgent);
    const config:AxiosRequestConfig = {
      params: {
        isAgent
      }
    }
    let url = `/affiliate/login`;
    if (isAgent) {
      url += `?isAgent=${isAgent}`
    }
    console.log(url)
    axios
      .post(url, data )
      .then((response) => {
        console.log(response);
        openToast(true, response.data.message, "Great!");
        localStorage.setItem("vobbAffiliateToken", response.data.data.token);
        push(Routes.overview);
      })
      .catch((error) => {
        console.error(error);
        console.error(error.response);
        openToast(false, error.response.data.message, "Sorry");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const openToast = (type: boolean, text: string, heading: string) => {
    setToast({ show: true, type: type, text: text, heading: heading });
    return setTimeout(() => {
      setToast({ ...toast, show: false });
    }, 1500);
  };

  return (
    <>
      {loading && <Preloader />}
      <Toast
        closeModal={() => setToast({ ...toast, show: false })}
        show={toast.show}
        text={toast.text}
        heading={toast.heading}
        type={toast.type}
      />
      <LoginUI
        login={login}
        handleChangeEmail={(email) => setEmail(email)}
        handleChangePassword={(password) => setPassword(password)}
        handleChangeIsAgent={(isAgent) => setIsAgent(isAgent)}
      />
    </>
  );
};

export default Login;
