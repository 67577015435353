import * as React from "react";
import styles from "./styles.module.css";
import SendIcon from "assets/vectors/sendGreen.svg";
import MessageIcon from "assets/vectors/messageGreen.svg";
import profile from "assets/vectors/profileDark.svg";
import PartnersIcon from "assets/vectors/partnersPurple.svg";
import emptyState from "assets/vectors/emptyState.svg";
import Navbar from "Components/Navbar";
import { Affiliate, AffiliateActivity, Summary } from "Pages/Dashboard";

export interface DashboardProps {
  summary: Summary;
  activity: AffiliateActivity[];
  affiliate: Affiliate;
  copyText: (id: string) => void;
}

const DashboardUI: React.FC<DashboardProps> = ({
  summary,
  activity,
  affiliate,
  copyText,
}) => {
  console.log(affiliate.referralId);
  const [copied, setCopied] = React.useState<boolean>(false);

  const copy = (id) => {
    setCopied(true);
    copyText(id);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  return (
    <>
      <Navbar />
      <div className={styles.container}>
        <div className={styles.header}>
          <div>
            <p className={styles.name}>
              Hi <span>👋🏽</span> {affiliate.name}
            </p>
            <p className={styles.subtitle}>
              You can do a lot with your dashboard, explore!
            </p>
          </div>
          <button
            onClick={() => copy(affiliate.referralId)}
            className={`${styles.referralBtn} appBtnFill`}
          >
            {copied ? "Copied!" : "Copy referral link"}
          </button>
        </div>
        <hr className={styles.rule} />

        <div className={styles.box}>
          <p className={styles.title}>Overview</p>
          <div className={styles.cardBox}>
            <div className={styles.card}>
              <img
                src={PartnersIcon}
                alt="stats"
                style={{ background: "#E8E4F6" }}
                className={styles.cardIcon}
              />
              <p className={styles.cardNum}>
                <span className={styles.partnershipsNum}>{summary.agents}</span>
              </p>
              <p className={styles.cardText}>Agent(s)</p>
            </div>
            <div className={styles.card}>
              <img
                src={SendIcon}
                alt="stats"
                style={{ background: "#E5F6EF" }}
                className={styles.cardIcon}
              />
              <p className={styles.cardNum}>
                <span className={styles.applicationsNum}>
                  {summary.sentApplications}
                </span>
              </p>
              <p className={styles.cardText}>Application(s) Sent</p>
            </div>
            <div className={styles.card}>
              <img
                src={MessageIcon}
                alt="stats"
                style={{ background: "#E1ECFF" }}
                className={styles.cardIcon}
              />
              <p className={styles.cardNum}>
                <span className={styles.admissionsNum}>
                  ${summary.potentialEarnings}
                </span>
              </p>
              <p className={styles.cardText}>Total Potential Earnings</p>
            </div>
            <div className={styles.card}>
              <img
                src={MessageIcon}
                alt="stats"
                style={{ background: "#E1ECFF" }}
                className={styles.cardIcon}
              />
              <p className={styles.cardNum}>
                <span className={styles.admissionsNum}>
                  ${summary.actualEarnings}
                </span>
              </p>
              <p className={styles.cardText}>Total Actual Earnings</p>
            </div>
          </div>
        </div>
        <div className={styles.recentBox}>
          <p className={styles.recentTitle}>Affiliate Activity</p>
          {activity.length > 0 ? (
            <div className={styles.list}>
              <div className={`${styles.tableStructure} ${styles.tableHead}`}>
                <div>Agent</div>
                <div>Sent Applications</div>
                <div>Potential Earnings</div>
                <div>Actual Earnings</div>
              </div>
              {activity.map((item) => (
                <div className={`${styles.tableStructure} ${styles.tableBody}`}>
                  <div className={styles.bodySectionOne}>
                    <div>
                      <img src={profile} alt="avatar" />
                    </div>
                    <span>{item.name}</span>
                  </div>
                  <div>
                    <span className={styles.mobileTtl}>Sent Applications:</span>
                    {item.sentApps}
                  </div>
                  <div>
                    <span className={styles.mobileTtl}>
                      Potential Earnings:
                    </span>
                    ${item.potentialEarnings}
                  </div>
                  <div>
                    <span className={styles.mobileTtl}>Actual Earnings:</span>$
                    {item.actualEarnings}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className={styles.emptyState}>
              <img src={emptyState} alt="empty" />
              <p>You have no referrals yet. <br />Send your referral link to agents to get start getting benefits!</p>
              <button
                onClick={() => copy(affiliate.referralId)}
                className={`${styles.referralBtn} appBtnFill`}
              >
                {copied ? "Copied!" : "Copy referral link"}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DashboardUI;
