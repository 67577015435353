import * as React from "react";
import { Modal } from "react-bootstrap";
import styles from "./styles.module.css";
import power from "assets/vectors/power.png";

interface ModalProps {
  show: boolean;
  closeModal: () => void;
  logout: () => void;
}

const LogoutPrompt: React.FC<ModalProps> = ({ show, closeModal, logout }) => {
  return (
    <Modal onHide={closeModal} show={show} className={styles["v-dash-nav-logout-wrap"]}>
      <Modal.Body onClick={logout} className={styles["v-dash-nav-logout"]}>
        <img src={power} alt="logout" /> Logout
      </Modal.Body>
    </Modal>
  );
};
export default LogoutPrompt;