import * as React from "react";
import LeftTab from "Components/LeftTab";
import { Link } from "react-router-dom";
import verify from "assets/vectors/verify.svg";
import logo from "assets/vectors/vobbSymbol.svg";
import { Routes } from "Utils/routes";
import styles from "./styles.module.css"

interface VerifyProps {
  resendVerification: () => void;
  email: string;
}

const VerifyUI:React.FC<VerifyProps> = ({resendVerification, email}) => {
    return (
    <>
      <div className={styles["container"]}>
        <LeftTab
          text="Discover and interact with credible international study abroad agents for free!"
          img={verify}
          page="forgot"
          margin="small"
        />
        <div className={styles["info"]}>
          <div className={styles["mobile-hd"]}>
            <Link to={Routes.register}>
              <img src={logo} alt="vobb logo" />
            </Link>
          </div>
          <div className={`${styles["info-content"]} ${styles["v-verify-body"]}`}>
            <h1 className={styles["info-ttl"]}>Verify your Email</h1>
            <p className={styles["info-txt"]}>
              We have sent a link to your email address:{" "}
              <span className={styles["v-verifymailexample"]}>{email}</span>
              <br />
              Please check your promotions tab if you can't find it under your
              primary mails!
            </p>
            <button
              onClick={resendVerification}
              className={`${styles["form-btn"]} ${styles["form-btn-active"]}`}
            >
              Resend
            </button>
            <p className={styles["form-txt"]}>
              Click here to <Link to="/login">Login</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyUI;
