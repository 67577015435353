import * as React from "react";
import { Routes } from "Utils/routes";
import Toast from "Components/Toast/toast";
import axios from "requests/axios-base";
import queryString from "query-string";
import Preloader from "Components/Preloader/preloader";
import ResetUI from "Components/ResetPassword";

const Reset = (props: any) => {
  const [token, setToken] = React.useState<any>("");
  const [password1, setPassword1] = React.useState<any>("");
  const [password2, setPassword2] = React.useState<any>("");
  const [loading, setLoading] = React.useState(false);
  const [toast, setToast] = React.useState({
    show: false,
    heading: "",
    text: "",
    type: false,
  });

  React.useEffect(() => {
    // setLoading(true);
    const values = queryString?.parse(props.location.search);
    setToken(values?.token);
  }, []);

  const reset = () => {
    setLoading(true);
    const data = {
      newPassword: password1,
      confirmPassword: password2,
    };
    axios
      .patch(`/affiliate/reset-password?token=${token}`, data)
      .then((response) => {
        // console.log(response);
        openToast(true, response.data.message, "Great!");

        setPassword1("");
        setPassword2("");
        props.history.push(Routes.login);
      })
      .catch((error) => {
        console.log(error.response);
        openToast(false, error.response.data.message, "Sorry");
      })
      .finally(() => setLoading(false));
  };
  const openToast = (type: boolean, text: string, heading: string) => {
    setToast({ show: true, type: type, text: text, heading: heading });
    return setTimeout(() => {
      setToast({ ...toast, show: false });
    }, 1500);
  };
  return (
    <>
      {loading && <Preloader />}
      <Toast
        closeModal={() => setToast({ ...toast, show: false })}
        show={toast.show}
        text={toast.text}
        heading={toast.heading}
        type={toast.type}
      />
      <ResetUI
        reset={reset}
        token={token}
        onConfirmPasswordChange={(password) => setPassword1(password)}
        onPasswordChange={(password) => setPassword2(password)}
      />
    </>
  );
};

export default Reset;
