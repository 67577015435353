import * as React from "react";
import { Modal } from "react-bootstrap";
import styles from "./toast.module.css";
import check from "assets/vectors/check.svg";

interface ToastProps {
  heading: string;
  text: string;
  closeModal: () => void;
  show: boolean;
  type: boolean;
}

const Toast: React.FC<ToastProps> = ({
  heading,
  text,
  closeModal,
  show,
  type,
}) => {
  return (
    <>
      <Modal
        dialogClassName={styles.dialog}
        contentClassName={
          type ? styles["content-green"] : styles["content-red"]
        }
        show={show}
        onHide={closeModal}
      >
        <Modal.Body className={styles.body}>
          {type ? (
            <img className={styles["v-toast-img"]} src={check} alt="check" />
          ) : (
            <div className={styles["v-toast-red-icon"]}>&times;</div>
          )}
          <div>
            <h6 className={styles["v-toast-ttl"]}>{heading}</h6>
            <p className={styles["v-toast-txt"]}>{text}</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Toast;
