import * as React from "react";
import styles from "./styles.module.css";
import success from "assets/gifs/success.gif";
import logo from "assets/vectors/vobbSymbol.svg";
import { Link } from "react-router-dom";
import { Routes } from "Utils/routes";
import LeftTab from "Components/LeftTab";
import verifyImg from "assets/vectors/verify.svg";

interface SuccessProps {
  verify: (email: string) => void;
  status: boolean;
}

const SuccessUI: React.FC<SuccessProps> = ({ verify, status }) => {
  const [email, setEmail] = React.useState("");

  return (
    <>
      {status ? (
        <div className={styles["container"]}>
          <LeftTab
            text="Discover and interact with credible international study abroad agents for free!"
            img={verifyImg}
            page="forgot"
            margin="small"
          />
          <div className={styles["info"]}>
            <div className={styles["mobile-hd"]}>
              <Link to={Routes.register}>
                <img
                  className={styles["success-logo"]}
                  src={logo}
                  alt="vobb logo"
                />
              </Link>
            </div>
            <div className={styles["info-content"]}>
              <h1 className={styles["info-ttl success-ttl"]}>
                Welcome to Vobb
              </h1>
              <img
                className={styles["success-img"]}
                src={success}
                alt="success"
              />
              <p className={styles["success-txt"]}>
                Your account verification was successful!
                <br />
                Login to continue.
              </p>
              <Link to={Routes.login} className={styles["success-link"]}>
                Login
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={styles["noverify"]}>
            <img
              className={styles["noverify-logo"]}
              src={logo}
              alt="vobb logo"
            />
            Verification failed, please try again.
            <input
              onChange={(e) => setEmail(e.target.value)}
              name={email}
              className={styles["noverify-input"]}
              type="email"
              placeholder="Email address"
            />
            <button
              disabled={!email}
              onClick={() => verify(email)}
              className={styles["success-link"]}
            >
              Verify
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default SuccessUI;
