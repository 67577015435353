import * as React from "react";
import DashboardUI from "Components/Dashboard";
import axios from "requests/axios-base";
import Preloader from "Components/Preloader/preloader";

export interface AffiliateActivity {
  actualEarnings: number;
  name: string;
  potentialEarnings: number;
  sentApps: number;
  _id: string;
}

export interface Summary {
  agents: number;
  sentApplications: number;
  potentialEarnings: number;
  actualEarnings: number;
}

export interface Affiliate {
  referralId: number;
  name: number;
  email: number;
}

const initialSummary: Summary = {
  agents: 0,
  sentApplications: 0,
  potentialEarnings: 0,
  actualEarnings: 0,
};

const initialAffilate: Affiliate = {
  referralId:  0,
  name:  0,
  email:  0,
};

const Dashboard = () => {
  const [summary, setSummary] = React.useState<Summary>(initialSummary);
  const [activity, setActivity] = React.useState<AffiliateActivity[]>([]);
  const [affiliate, setAffiliate] = React.useState<Affiliate>(initialAffilate);
  const [loading, setLoading] = React.useState<boolean>(false);

  const getData = () => {
    setLoading(true);
    axios
      .get("/affiliate/dashboard")
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setSummary({
            agents: response.data.data.agentsReferred,
            sentApplications: response.data.data.sentApps,
            potentialEarnings: response.data.data.potentialEarnings,
            actualEarnings: response.data.data.actualEarnings,
          });
          setActivity(response.data.data.agents);
          setAffiliate({
            referralId: response.data.data.ref_id,
            name: response.data.data.name,
            email: response.data.data.email,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
      })
      .finally(() => {
        setLoading(false)
      })
  };

  React.useEffect(() => {
    getData();
  }, []);

  const copyText = (ref_id) => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_SIGNUP_URL}?ref=${ref_id}`)
  }


  return (
    <>
      {loading && <Preloader />}
      <DashboardUI summary={summary} activity={activity} affiliate={affiliate} copyText={(id) => copyText(id)} />
    </>
  );
};

export default Dashboard;
